export const columns = [
{
    title: '用户id',
    dataIndex: 'userId',
    key: 'userId',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '角色id',
    dataIndex: 'roleId',
    key: 'roleId',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
]
